var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"conections"}},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"3","lg":"4","md":"4","xl":"4"}},[_c('conection-card',{attrs:{"title":_vm.$t('accounts.google.title'),"description":_vm.$t('accounts.google.description'),"icon":require('@/assets/gmail.svg')}},[[(
              !_vm.accounts.find(function (x) { return x.key == 'account_google_access_token'; })
            )?_c('v-btn',{staticClass:"pl-5",attrs:{"elevation":"0","outlined":""},on:{"click":function($event){return _vm.gmailConnect()}}},[_vm._v(_vm._s(_vm.$t("accounts.connect")))]):_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","elevation":"0"}},on),[_vm._v(_vm._s(_vm.$t("accounts.configuration")))])]}}],null,false,2626341403),model:{value:(_vm.dialog_google),callback:function ($$v) {_vm.dialog_google=$$v},expression:"dialog_google"}},[_c('v-card',{attrs:{"id":"card_google"}},[_c('v-card-title',[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-icon',{staticStyle:{"display":"block","position":"relative","top":"0","right":"0","opacity":"1"},attrs:{"size":"45"}},[_vm._v("$gmail")])],1)]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog_google = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_vm._l((_vm.googleAccounts),function(account){return _c('v-row',{key:account.user.id,staticClass:"primary--text",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-avatar',[_c('img',{attrs:{"src":account.user.picture,"alt":account.user.name}})])],1),_c('v-col',{staticClass:"no-wrap pr-0",attrs:{"cols":"8"}},[_vm._v(_vm._s(account.user.email))]),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.disconect('gmail', account.user.email)}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("accounts.disconnect"))+" ")])],1)],1)}),_c('v-row',{attrs:{"justify":"center"}},[(_vm.$store.getters['auth/getPlan']('multiple_mail'))?_c('v-btn',_vm._g({staticClass:"mx-auto",attrs:{"color":"primary","elevation":"0","fab":"","small":"","outlined":""},on:{"click":function($event){return _vm.gmailConnect()}}},_vm.on),[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)],2)],1)],1)]],2)],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"3","lg":"4","md":"4","xl":"4"}},[_c('conection-card',{attrs:{"title":_vm.$t('accounts.IMAP.title'),"description":_vm.$t('accounts.IMAP.description'),"icon":require('@/assets/imap.svg')}},[[(!_vm.accounts.find(function (x) { return x.key == 'account_IMAP_access_token'; }))?_c('imap-dialog',{attrs:{"imap":_vm.IMAP},on:{"validated":function($event){return _vm.IMAPConnect()}}}):_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){return _vm.disconect('imap')}}},[_vm._v(_vm._s(_vm.$t("accounts.disconnect")))])]],2)],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"3","lg":"4","md":"4","xl":"4"}},[_c('conection-card',{attrs:{"title":_vm.$t('accounts.outlook.title'),"description":_vm.$t('accounts.outlook.description'),"icon":require('@/assets/outlook.png')}},[[(
              !_vm.accounts.find(function (x) { return x.key == 'account_microsoft_access_token'; })
            )?_c('v-btn',{staticClass:"pl-5",attrs:{"elevation":"0","outlined":"","disabled":""},on:{"click":function($event){return _vm.outlookConnect()}}},[_vm._v(_vm._s(_vm.$t("accounts.connect")))]):_c('v-btn',{attrs:{"disabled":"","color":"primary","elevation":"0"},on:{"click":function($event){return _vm.disconect('outlook')}}},[_vm._v(_vm._s(_vm.$t("accounts.disconnect")))])]],2)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-row',{staticClass:"ma-0",staticStyle:{"max-width":"100%"}},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"4","lg":"4","md":"4","xl":"3"}},[_c('conection-card',{attrs:{"title":_vm.$t('accounts.google.title'),"icon":require('@/assets/gmail.svg')}},[_c('v-row',{attrs:{"justify":"center"}},[(
              !_vm.accounts.find(function (x) { return x.key == 'account_google_access_token'; })
            )?_c('v-btn',{attrs:{"outlined":"","elevation":"0","width":_vm.$vuetify.breakpoint.xsOnly ? '100' : ''},on:{"click":function($event){return _vm.gmailConnect()}}},[_vm._v(_vm._s(_vm.$t("accounts.connect")))]):_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","elevation":"0","width":_vm.$vuetify.breakpoint.xsOnly ? '100' : ''}},on),[_vm._v(_vm._s(_vm.$t("accounts.configuration_xs")))])]}}],null,false,1204156455),model:{value:(_vm.dialog_google),callback:function ($$v) {_vm.dialog_google=$$v},expression:"dialog_google"}},[_c('v-card',{attrs:{"id":"card_google"}},[_c('v-card-title',[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-icon',{staticStyle:{"display":"block","position":"relative","top":"0","right":"0","opacity":"1"},attrs:{"size":"45"}},[_vm._v("$gmail")])],1)]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog_google = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_vm._l((_vm.googleAccounts),function(account){return _c('v-row',{key:account.user.id,staticClass:"primary--text",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-avatar',[_c('img',{attrs:{"src":account.user.picture,"alt":account.user.name}})])],1),_c('v-col',{staticClass:"no-wrap pr-0",attrs:{"cols":"8"}},[_vm._v(_vm._s(account.user.email))]),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.disconect('gmail', account.user.email)}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("accounts.disconnect"))+" ")])],1)],1)}),_c('v-row',{attrs:{"justify":"center"}},[(_vm.$store.getters['auth/getPlan']('multiple_mail'))?_c('v-btn',_vm._g({staticClass:"mx-auto",attrs:{"color":"primary","elevation":"0","fab":"","small":"","outlined":""},on:{"click":function($event){return _vm.gmailConnect()}}},_vm.on),[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)],2)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"4","lg":"4","md":"4","xl":"3"}},[_c('conection-card',{attrs:{"title":_vm.$t('accounts.IMAP.title'),"icon":require('@/assets/imap.svg')}},[_c('v-row',{attrs:{"justify":"center"}},[(!_vm.accounts.find(function (x) { return x.key == 'account_IMAP_access_token'; }))?_c('imap-dialog',{attrs:{"imap":_vm.IMAP},on:{"validated":function($event){return _vm.IMAPConnect()}}}):_c('v-btn',{attrs:{"color":"primary","elevation":"0","width":_vm.$vuetify.breakpoint.xsOnly ? '100' : ''},on:{"click":function($event){return _vm.disconect('imap')}}},[_vm._v(_vm._s(_vm.$t("accounts.disconnect"))+" ")])],1)],1)],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"4","lg":"4","md":"4","xl":"3"}},[_c('conection-card',{attrs:{"title":_vm.$t('accounts.outlook.title'),"icon":require('@/assets/outlook.png')}},[_c('v-row',{attrs:{"justify":"center"}},[(
              !_vm.accounts.find(function (x) { return x.key == 'account_microsoft_access_token'; })
            )?_c('v-btn',{staticClass:"pl-5",attrs:{"elevation":"0","disabled":"","outlined":"","width":_vm.$vuetify.breakpoint.xsOnly ? '100' : ''},on:{"click":function($event){return _vm.outlookConnect()}}},[_vm._v(_vm._s(_vm.$t("accounts.connect")))]):_c('v-btn',{attrs:{"disabled":"","color":"primary","width":_vm.$vuetify.breakpoint.xsOnly ? '100' : '',"elevation":"0"},on:{"click":function($event){return _vm.disconect('outlook')}}},[_vm._v(_vm._s(_vm.$t("accounts.disconnect"))+" ")])],1)],1)],1)],1):_vm._e(),_c('div',[(
        _vm.$store.getters['auth/isTattooer'] || _vm.$store.getters['auth/isStudio']
      )?_c('v-row',[_c('v-col',{staticClass:"px-1 py-0 px-md-6",attrs:{"cols":"12"}},[_c('h1',{staticStyle:{"text-transform":"uppercase","font-weight":"normal"}},[_vm._v(" "+_vm._s(_vm.$t("accounts.social_media"))+" ")])]),_c('v-col',{staticClass:"px-4 py-0 px-md-6",attrs:{"cols":"6","md":"3"}},[_c('conection-card',{staticClass:"title",attrs:{"title":"INSTAGRAM","icon":require('@/assets/instagram.png')}},[_c('v-row',{attrs:{"justify":"center"}},[(
                _vm.user.social_networks &&
                  _vm.user.social_networks.hasOwnProperty('instagram')
              )?_c('v-btn',{attrs:{"elevation":"0"},on:{"click":function($event){return _vm.addRRSS('instagram')}}},[_c('span',{staticClass:"RRSS"},[_vm._v(_vm._s(_vm.user.social_networks.instagram))]),_c('v-icon',{staticClass:"mt-1",staticStyle:{"position":"absolute","right":"0"},attrs:{"right":""},on:{"click":function($event){$event.stopPropagation();return _vm.delRRSS('instagram')}}},[_vm._v("mdi-close-circle-outline")])],1):_c('v-btn',{attrs:{"outlined":"","elevation":"0"},on:{"click":function($event){return _vm.addRRSS('instagram')}}},[_vm._v(_vm._s(_vm.$t("accounts.connect")))])],1)],1)],1),_c('v-col',{staticClass:"px-4 py-0 px-md-6 ",attrs:{"cols":"6","md":"3"}},[_c('conection-card',{staticClass:"title",attrs:{"title":"TIK TOK","icon":require('@/assets/tik-tok.svg')}},[_c('v-row',{attrs:{"justify":"center"}},[(
                _vm.user.social_networks &&
                  _vm.user.social_networks.hasOwnProperty('tiktok')
              )?_c('v-btn',{attrs:{"elevation":"0"},on:{"click":function($event){return _vm.addRRSS('tiktok')}}},[_c('span',{staticClass:"RRSS"},[_vm._v(_vm._s(_vm.user.social_networks.tiktok))]),_c('v-icon',{staticClass:"mt-1",staticStyle:{"position":"absolute","right":"0"},attrs:{"right":""},on:{"click":function($event){$event.stopPropagation();return _vm.delRRSS('tiktok')}}},[_vm._v("mdi-close-circle-outline")])],1):_c('v-btn',{attrs:{"outlined":"","elevation":"0"},on:{"click":function($event){return _vm.addRRSS('tiktok')}}},[_vm._v(_vm._s(_vm.$t("accounts.connect")))])],1)],1)],1),_c('v-col',{staticClass:"px-4 py-0 px-md-6 ",attrs:{"cols":"6","md":"3"}},[_c('conection-card',{staticClass:"title",attrs:{"title":"TWITTER","icon":require('@/assets/twitter.png')}},[_c('v-row',{attrs:{"justify":"center"}},[(
                _vm.user.social_networks &&
                  _vm.user.social_networks.hasOwnProperty('twitter')
              )?_c('v-btn',{attrs:{"elevation":"0"},on:{"click":function($event){return _vm.addRRSS('twitter')}}},[_c('span',{staticClass:"RRSS"},[_vm._v(_vm._s(_vm.user.social_networks.twitter))]),_c('v-icon',{staticClass:"mt-1",staticStyle:{"position":"absolute","right":"0"},attrs:{"right":""},on:{"click":function($event){$event.stopPropagation();return _vm.delRRSS('twitter')}}},[_vm._v("mdi-close-circle-outline")])],1):_c('v-btn',{attrs:{"outlined":"","elevation":"0"},on:{"click":function($event){return _vm.addRRSS('twitter')}}},[_vm._v(_vm._s(_vm.$t("accounts.connect")))])],1)],1)],1),_c('v-col',{staticClass:"px-4 py-0 px-md-6 ",attrs:{"cols":"6","md":"3"}},[_c('conection-card',{staticClass:"title",attrs:{"title":"FACEBOOK","icon":require('@/assets/facebook.png')}},[_c('v-row',{attrs:{"justify":"center"}},[(
                _vm.user.social_networks &&
                  _vm.user.social_networks.hasOwnProperty('facebook')
              )?_c('v-btn',{attrs:{"elevation":"0"},on:{"click":function($event){return _vm.addRRSS('facebook')}}},[_c('span',{staticClass:"RRSS"},[_vm._v(_vm._s(_vm.user.social_networks.facebook))]),_c('v-icon',{staticClass:"mt-1",staticStyle:{"position":"absolute","right":"0"},attrs:{"right":""},on:{"click":function($event){$event.stopPropagation();return _vm.delRRSS('facebook')}}},[_vm._v("mdi-close-circle-outline")])],1):_c('v-btn',{attrs:{"outlined":"","elevation":"0"},on:{"click":function($event){return _vm.addRRSS('facebook')}}},[_vm._v(_vm._s(_vm.$t("accounts.connect")))])],1)],1)],1)],1):_vm._e()],1),_c('CalendarConfiguration',{ref:"calendar_configuration"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }