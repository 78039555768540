<template>
  <div id="conections">
    <div
      v-if="!$vuetify.breakpoint.smAndDown"
      style="display: flex; justify-content: space-between"
    >
      <v-col cols="3" lg="4" md="4" xl="4" class="px-2">
        <conection-card
          :title="$t('accounts.google.title')"
          :description="$t('accounts.google.description')"
          :icon="require('@/assets/gmail.svg')"
        >
          <template>
            <v-btn
              elevation="0"
              class="pl-5"
              outlined
              v-if="
                !accounts.find((x) => x.key == 'account_google_access_token')
              "
              @click="gmailConnect()"
              >{{ $t("accounts.connect") }}</v-btn
            >
            <v-dialog v-else width="400" v-model="dialog_google">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="primary" elevation="0">{{
                  $t("accounts.configuration")
                }}</v-btn>
              </template>
              <v-card id="card_google">
                <v-card-title>
                  <div style="display: flex; justify-content: center">
                    <v-icon
                      size="45"
                      style="
                        display: block;
                        position: relative;
                        top: 0;
                        right: 0;
                        opacity: 1;
                      "
                      >$gmail</v-icon
                    >
                  </div>
                </v-card-title>
                <div class="close">
                  <v-btn icon @click="dialog_google = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <v-card-text>
                  <v-row
                    class="primary--text"
                    v-for="account in googleAccounts"
                    :key="account.user.id"
                    align="center"
                  >
                    <v-col cols="2">
                      <v-avatar>
                        <img
                          :src="account.user.picture"
                          :alt="account.user.name"
                        />
                      </v-avatar>
                    </v-col>
                    <v-col cols="8" class="no-wrap pr-0">{{
                      account.user.email
                    }}</v-col>
                    <v-col cols="2" class="pl-0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                            @click="disconect('gmail', account.user.email)"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </template>
                        {{ $t("accounts.disconnect") }}
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-btn
                      v-on="on"
                      color="primary"
                      elevation="0"
                      fab
                      small
                      outlined
                      class="mx-auto"
                      @click="gmailConnect()"
                      v-if="$store.getters['auth/getPlan']('multiple_mail')"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
        </conection-card>
      </v-col>

      <v-col cols="3" lg="4" md="4" xl="4" class="px-2">
        <conection-card
          :title="$t('accounts.IMAP.title')"
          :description="$t('accounts.IMAP.description')"
          :icon="require('@/assets/imap.svg')"
        >
          <template>
            <imap-dialog
              v-if="!accounts.find((x) => x.key == 'account_IMAP_access_token')"
              @validated="IMAPConnect()"
              :imap="IMAP"
            />
            <v-btn
              v-else
              @click="disconect('imap')"
              color="primary"
              elevation="0"
              >{{ $t("accounts.disconnect") }}</v-btn
            >
          </template>
        </conection-card>
      </v-col>
      <v-col cols="3" lg="4" md="4" xl="4" class="px-2">
        <conection-card
          :title="$t('accounts.outlook.title')"
          :description="$t('accounts.outlook.description')"
          :icon="require('@/assets/outlook.png')"
        >
          <template>
            <v-btn
              elevation="0"
              class="pl-5"
              outlined
              disabled
              v-if="
                !accounts.find((x) => x.key == 'account_microsoft_access_token')
              "
              @click="outlookConnect()"
              >{{ $t("accounts.connect") }}</v-btn
            >
            <v-btn
              v-else
              @click="disconect('outlook')"
              disabled
              color="primary"
              elevation="0"
              >{{ $t("accounts.disconnect") }}</v-btn
            >
          </template>
        </conection-card>
      </v-col>
    </div>

    <v-row
      style="max-width: 100%"
      class="ma-0"
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <v-col cols="4" lg="4" md="4" xl="3" class="px-2">
        <conection-card
          :title="$t('accounts.google.title')"
          :icon="require('@/assets/gmail.svg')"
        >
          <v-row justify="center">
            <v-btn
              outlined
              elevation="0"
              v-if="
                !accounts.find((x) => x.key == 'account_google_access_token')
              "
              @click="gmailConnect()"
              :width="$vuetify.breakpoint.xsOnly ? '100' : ''"
              >{{ $t("accounts.connect") }}</v-btn
            >
            <v-dialog v-else width="400" v-model="dialog_google">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  color="primary"
                  elevation="0"
                  :width="$vuetify.breakpoint.xsOnly ? '100' : ''"
                  >{{ $t("accounts.configuration_xs") }}</v-btn
                >
              </template>
              <v-card id="card_google">
                <v-card-title>
                  <div style="display: flex; justify-content: center">
                    <v-icon
                      size="45"
                      style="
                        display: block;
                        position: relative;
                        top: 0;
                        right: 0;
                        opacity: 1;
                      "
                      >$gmail</v-icon
                    >
                  </div>
                </v-card-title>
                <div class="close">
                  <v-btn icon @click="dialog_google = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <v-card-text>
                  <v-row
                    class="primary--text"
                    v-for="account in googleAccounts"
                    :key="account.user.id"
                    align="center"
                  >
                    <v-col cols="2">
                      <v-avatar>
                        <img
                          :src="account.user.picture"
                          :alt="account.user.name"
                        />
                      </v-avatar>
                    </v-col>
                    <v-col cols="8" class="no-wrap pr-0">{{
                      account.user.email
                    }}</v-col>
                    <v-col cols="2" class="pl-0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                            @click="disconect('gmail', account.user.email)"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </template>
                        {{ $t("accounts.disconnect") }}
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-btn
                      v-on="on"
                      color="primary"
                      elevation="0"
                      fab
                      small
                      outlined
                      class="mx-auto"
                      @click="gmailConnect()"
                      v-if="$store.getters['auth/getPlan']('multiple_mail')"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
        </conection-card>
      </v-col>
      <v-col cols="4" lg="4" md="4" xl="3" class="px-2">
        <conection-card
          :title="$t('accounts.IMAP.title')"
          :icon="require('@/assets/imap.svg')"
        >
          <v-row justify="center">
            <imap-dialog
              v-if="!accounts.find((x) => x.key == 'account_IMAP_access_token')"
              @validated="IMAPConnect()"
              :imap="IMAP"
            />
            <v-btn
              v-else
              @click="disconect('imap')"
              color="primary"
              elevation="0"
              :width="$vuetify.breakpoint.xsOnly ? '100' : ''"
              >{{ $t("accounts.disconnect") }}
            </v-btn>
          </v-row>
        </conection-card>
      </v-col>
      <v-col cols="4" lg="4" md="4" xl="3" class="px-2">
        <conection-card
          :title="$t('accounts.outlook.title')"
          :icon="require('@/assets/outlook.png')"
        >
          <v-row justify="center">
            <v-btn
              elevation="0"
              disabled
              outlined
              class="pl-5"
              v-if="
                !accounts.find((x) => x.key == 'account_microsoft_access_token')
              "
              @click="outlookConnect()"
              :width="$vuetify.breakpoint.xsOnly ? '100' : ''"
              >{{ $t("accounts.connect") }}</v-btn
            >
            <v-btn
              v-else
              disabled
              @click="disconect('outlook')"
              color="primary"
              :width="$vuetify.breakpoint.xsOnly ? '100' : ''"
              elevation="0"
              >{{ $t("accounts.disconnect") }}
            </v-btn>
          </v-row>
        </conection-card>
      </v-col>
    </v-row>
    <div>
      <v-row
        v-if="
          $store.getters['auth/isTattooer'] || $store.getters['auth/isStudio']
        "
      >
        <v-col cols="12" class="px-1 py-0 px-md-6"
          ><h1 style="text-transform: uppercase; font-weight: normal">
            {{ $t("accounts.social_media") }}
          </h1></v-col
        >

        <v-col cols="6" md="3" class="px-4 py-0 px-md-6">
          <conection-card
            class="title"
            title="INSTAGRAM"
            :icon="require('@/assets/instagram.png')"
          >
            <v-row justify="center">
              <v-btn
                elevation="0"
                v-if="
                  user.social_networks &&
                    user.social_networks.hasOwnProperty('instagram')
                "
                @click="addRRSS('instagram')"
                ><span class="RRSS">{{ user.social_networks.instagram }}</span>
                <v-icon
                  @click.stop="delRRSS('instagram')"
                  right
                  class="mt-1"
                  style="position: absolute; right: 0"
                  >mdi-close-circle-outline</v-icon
                ></v-btn
              >
              <v-btn
                v-else
                outlined
                @click="addRRSS('instagram')"
                elevation="0"
                >{{ $t("accounts.connect") }}</v-btn
              >
            </v-row>
          </conection-card>
        </v-col>
        <v-col cols="6" md="3" class="px-4 py-0 px-md-6 ">
          <conection-card
            class="title"
            title="TIK TOK"
            :icon="require('@/assets/tik-tok.svg')"
          >
            <v-row justify="center">
              <v-btn
                elevation="0"
                v-if="
                  user.social_networks &&
                    user.social_networks.hasOwnProperty('tiktok')
                "
                @click="addRRSS('tiktok')"
              >
                <span class="RRSS">{{ user.social_networks.tiktok }}</span
                ><v-icon
                  @click.stop="delRRSS('tiktok')"
                  right
                  class="mt-1"
                  style="position: absolute; right: 0"
                  >mdi-close-circle-outline</v-icon
                ></v-btn
              >
              <v-btn v-else outlined @click="addRRSS('tiktok')" elevation="0">{{
                $t("accounts.connect")
              }}</v-btn>
            </v-row>
          </conection-card>
        </v-col>
        <v-col cols="6" md="3" class="px-4 py-0 px-md-6 ">
          <conection-card
            class="title"
            title="TWITTER"
            :icon="require('@/assets/twitter.png')"
          >
            <v-row justify="center">
              <v-btn
                elevation="0"
                v-if="
                  user.social_networks &&
                    user.social_networks.hasOwnProperty('twitter')
                "
                @click="addRRSS('twitter')"
                ><span class="RRSS">{{ user.social_networks.twitter }}</span
                ><v-icon
                  @click.stop="delRRSS('twitter')"
                  right
                  class="mt-1"
                  style="position: absolute; right: 0"
                  >mdi-close-circle-outline</v-icon
                ></v-btn
              >
              <v-btn
                v-else
                outlined
                @click="addRRSS('twitter')"
                elevation="0"
                >{{ $t("accounts.connect") }}</v-btn
              >
            </v-row>
          </conection-card>
        </v-col>
        <v-col cols="6" md="3" class="px-4 py-0 px-md-6 ">
          <conection-card
            class="title"
            title="FACEBOOK"
            :icon="require('@/assets/facebook.png')"
          >
            <v-row justify="center">
              <v-btn
                elevation="0"
                v-if="
                  user.social_networks &&
                    user.social_networks.hasOwnProperty('facebook')
                "
                @click="addRRSS('facebook')"
                ><span class="RRSS">{{ user.social_networks.facebook }}</span
                ><v-icon
                  @click.stop="delRRSS('facebook')"
                  right
                  class="mt-1"
                  style="position: absolute; right: 0"
                  >mdi-close-circle-outline</v-icon
                ></v-btn
              >
              <v-btn
                v-else
                outlined
                @click="addRRSS('facebook')"
                elevation="0"
                >{{ $t("accounts.connect") }}</v-btn
              >
            </v-row>
          </conection-card>
        </v-col>
      </v-row>
    </div>
    <CalendarConfiguration ref="calendar_configuration" />
  </div>
</template>

<script>
//! source: https://github.com/sunilbandla/vue-msal-sample/commit/f5bdf507209648a06a730f5879c5821dce543999
//! https://www.npmjs.com/package/@azure/msal-browser
import * as Msal from "@azure/msal-browser";
const MsalAppConfig = {
  init: {
    auth: {
      clientId: "6f6fec26-3bc0-4ec0-8cc3-c59fc3d3e3b2",
    },
  },
  request: {
    scopes: [
      "offline_access",
      "user.read",
      "Mail.ReadWrite",
      "Mail.Send",
      "MailboxSettings.Read",
      "Calendars.Read",
      "Calendars.ReadWrite",
      "Group.ReadWrite.All",
    ],
  },
};
const MsalApp = new Msal.PublicClientApplication(MsalAppConfig.init);

import { mapActions } from "vuex";
export default {
  name: "Conections",
  components: {
    ImapDialog: () =>
      import("@/components/user/settings/connection/IMAP/Dialog"),
    ConectionCard: () => import("@/components/user/settings/connection/Card"),
    CalendarConfiguration: () =>
      import("@/components/user/settings/connection/CalendarConfiguration"),
    SocialNetworks: () => import("@/components/ui/SocialNetworks"),
  },
  data() {
    return {
      accounts: [],
      IMAP: {
        email: null,
        password: null,
      },
      IMAPDialog: false,
      user: {},
      dialog_google: false,
    };
  },
  computed: {
    googleAccounts() {
      let s = this.accounts.find((x) => x.key == "account_google_access_token");
      if (s) return s.value;
      return [];
    },
  },
  mounted() {
    this.fetchAccounts();
    if (this.$store.getters["auth/isTattooer"])
      this.user = this.$store.state.auth.user.tattooer;
    if (this.$store.getters["auth/isStudio"])
      this.user = this.$store.state.auth.user.studio;
  },
  methods: {
    ...mapActions("oauth", ["getAccounts", "setAccount"]),

    fetchAccounts() {
      this.getAccounts().then((response) => {
        //console.log(response);
        this.accounts = response;
      });
    },
    async gmailConnect() {
      const authCode = await this.$gAuth.getAuthCode();
      //console.log(authCode);

      this.setAccount({ type: "google", authCode }).then((response) => {
        //console.log(response);
        if (response.error) {
          this.$alert(this.$t(response.error_message));
        } else {
          this.$alert(this.$t("account_conected")).then(() => {
            if (
              !this.$store.getters["auth/getSetting"](
                "calendar_relations_configurated"
              )
            )
              this.$confirm(this.$t("ask_calendar_configuration_now")).then(
                () => {
                  this.$refs.calendar_configuration.open("gmail");
                }
              );
          });
        }
        this.accounts = response.accounts;
        this.$store.dispatch("email/getUnreadEmails");
      });
    },
    disconect(account, email = "") {
      this.$confirm(
        this.$t("accounts.disconnect_account_question") +
          account +
          (email !== "" ? " " : "") +
          email +
          this.$t("accounts.question_mark"),
        "",
        ""
      ).then(() => {
        switch (account) {
          case "gmail":
            this.gmailDisconnect(email);
            break;
          case "outlook":
            this.outlookDisconnect();
            break;
          case "imap":
            this.IMAPDisconnect();
            break;
        }
      });
    },
    async gmailDisconnect(email) {
      const response = await this.$gAuth.signOut();
      if (response) {
        this.setAccount({ type: "google", authCode: response, email }).then(
          (response) => {
            //console.log("google", response);
            this.accounts = response.accounts;
            this.$store.dispatch("email/getUnreadEmails");
          }
        );
      }
    },
    outlookConnect() {
      MsalApp.acquireTokenPopup(MsalAppConfig.request)
        .then((loginResponse) => {
          //console.log("miscrosoft", loginResponse);
          let accessToken = {
            acces_token: loginResponse.accessToken,
            refresh_token: loginResponse.refresh_token,
            scopes: loginResponse.scopes,
          };
          //let refreshToken = loginResponse.refreshToken; // no funciona :(

          this.setAccount({ type: "microsoft", accessToken }).then(
            (response) => {
              if (response.error) {
                this.$alert(this.$t(response.error_message));
              } else {
                this.$alert(this.$t("account_conected"));
              }
              this.accounts = response.accounts;
              this.$store.dispatch("email/getUnreadEmails");
            }
          );
        })
        .catch((error) => {
          //console.log(error);
          if (error.errorCode == "popup_window_error") {
            alert(
              "Error opening popup window. This can happen if you are using IE or if popups are blocked in the browser."
            );
          }
        });
    },
    outlookDisconnect() {
      this.setAccount({ type: "microsoft", accessToken: true }).then(
        (response) => {
          this.accounts = response.accounts;
          this.$store.dispatch("email/getUnreadEmails");
          this.$nextTick(() => {
            MsalApp.logout();
          });
        }
      );
    },

    IMAPConnect() {
      this.setAccount({ type: "IMAP", accessToken: this.IMAP }).then(
        (response) => {
          if (response.error) {
            this.$alert("contraseña incorrecta");
          } else {
            this.$alert(this.$t("account_conected"));
          }

          this.accounts = response.accounts;
          this.$store.dispatch("email/getUnreadEmails");
        }
      );
    },

    IMAPDisconnect() {
      this.setAccount({ type: "IMAP", accessToken: true }).then((response) => {
        this.accounts = response.accounts;
        this.$store.dispatch("email/getUnreadEmails");
      });
    },
    ...mapActions("auth", ["updateRRSS"]),
    addRRSS(rrss) {
      if (
        this.user.social_networks === null ||
        this.user.social_networks === undefined ||
        typeof this.user.social_networks !== "object" ||
        this.user.social_networks.length === 0
      ) {
        console.log("transformamos");
        this.user.social_networks = {};
      }
      let link = "";
      if (this.user.social_networks.hasOwnProperty(rrss)) {
        link = this.user.social_networks[rrss];
      }
      this.$prompt(this.$t("user_name"), link, rrss).then((link) => {
        //console.log(link);
        this.$set(this.user.social_networks, rrss, link);
        this.saveRRSS();
      });
    },
    delRRSS(rrss) {
      this.$confirm(
        this.$t("accounts.disconnect_account_question") +
          rrss +
          this.$t("accounts.question_mark"),
        "",
        ""
      ).then(() => {
        if (Object.keys(this.user.social_networks).length > 1) {
          delete this.user.social_networks[rrss];
        } else {
          this.user.social_networks = null;
        }

        this.saveRRSS();
      });
    },
    saveRRSS() {
      this.updateRRSS(this.user).then((response) => {
        if (this.$store.getters["auth/isTattooer"])
          this.user = this.$store.state.auth.user.tattooer;
        if (this.$store.getters["auth/isStudio"])
          this.user = this.$store.state.auth.user.studio;
      });
    },
  },
};
</script>
<style lang="sass">
#card_google
  .conf
    top: 0
    right: 20px
    position: absolute
  .close .v-btn
    top: 0
    right: 0px
    position: absolute

    .v-icon
      color: var(--v-primary-base) !important
      position: absolute

#conections

  .v-btn.v-btn--contained.theme--dark.v-size--default
    width: 150px !important
    font-size: 14px
    height: 35px
  .col-4
    //max-width: none
  .v-btn:not(.v-btn--round).v-size--default
    width: 150px
    height: 35px
    font-size: 14px
.v-application .justify-center
  justify-content: center !important
.swal2-title
  text-decoration: underline
.RRSS
  white-space: nowrap
  width: 100px
  text-overflow: ellipsis
  overflow: hidden
.swal2-title, .swal2-content

  text-transform: uppercase
  color: var(--v-primary-base)

.swal2-input
  border: 1px solid var(--v-primary-base)

  border-radius: 90px
  height: 30px
  width: 80%
.swal2-input:focus
  box-shadow: 0px 0px 0px var(--v-primary-base)
  border-color: var(--v-primary-base)
</style>
